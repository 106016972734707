import "font-awesome/css/font-awesome.min.css";
import "react-day-picker/lib/style.css";
import "./index.css";
import "antd/dist/antd.css";

import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";

import createHistory from "history/createHashHistory";
import configureStore from "./Data/store/configureStore";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { init } from "./Data/actionCreators";
import Main from "./Views/Containers/Main";
import Login from "./Views/Containers/Login";
import Library from "./Views/Containers/Library";
import SelfCheck from "./Views/Containers/SelfCheck";
import LibraryCreate from "./Views/Containers/LibraryCreate";
import LibraryEdit from "./Views/Containers/LibraryEdit";
import SelfCheckCreate from "./Views/Containers/SelfCheckCreate";
import SelfCheckEdit from "./Views/Containers/SelfCheckEdit";
import Languages from "./Views/Containers/Languages";
import Settings from "./Views/Containers/Settings";
import Users from "./Views/Containers/Users";
import UserCreate from "./Views/Containers/UserCreate";
import UserEdit from "./Views/Containers/UserEdit";
import Theme from "./Views/Containers/Theme";
import CombinedStats from "./Views/Containers/CombinedStats";
import Payments from "./Views/Containers/Payments";
import ZReport from "./Views/Containers/ZReport";
import Help from "./Views/Containers/Help";
import Notifications from "./Views/Containers/Notifications";
import HelpMessages from "./Views/Containers/HelpMessages";

const history = createHistory();
const store = configureStore(history);
store.dispatch(init());

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route path="/login" component={Login} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/languages" component={Languages} />
        <Route path="/users/create" component={UserCreate} />
        <Route path="/users/:id" component={UserEdit} />
        <Route path="/users" component={Users} />
        <Route path="/libraries/create" component={LibraryCreate} />
        <Route path="/libraries/:id/edit" component={LibraryEdit} />
        <Route path="/libraries/:id/create" component={SelfCheckCreate} />
        <Route path="/libraries/:id" component={Library} />
        <Route path="/selfchecks/:id/edit" component={SelfCheckEdit} />
        <Route path="/selfchecks/:id" component={SelfCheck} />
        <Route path="/settings/:id/:tab?" component={Settings} />
        <Route path="/theme/:id/:screen?/:component?" component={Theme} />
        <Route path="/combined-stats" component={CombinedStats} />
        <Route
          path="/payments"
          component={(props) => <Payments {...props} type="payment" />}
        />
        <Route
          path="/councilPayments"
          component={(props) => <Payments {...props} type="council" />}
        />
        <Route path="/zreport" component={ZReport} />
        <Route path="/help" component={Help} />
        <Route path="/help-messages" component={HelpMessages} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
