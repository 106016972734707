/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FA from "react-fontawesome";

import {
  getLang,
  getCanAdmin,
  getUI,
  getCounts,
  getLibraries,
  getSelfCheck,
  getLibrary,
} from "../../Data/selectors";
import { DELETE_SC_PREFIX } from "../../Data/constants/actions";
import {
  startGetCounts,
  startDeleteSelfCheck,
  startLogout,
  startExportCounts,
} from "../../Data/actionCreators";

import { Container, Body, Footer } from "../StyledComponents/Page";
import { Btn } from "../StyledComponents/Forms";
import { Row, Col } from "../StyledComponents/Grid";
import { MainArea, Breadcrumb } from "../StyledComponents/Main";
import DateInput from "../Components/Inputs/DateInput";
import CountsChart from "../Components/CountsChart";
import MainSidebar from "../Components/MainSidebar";
import StatusMessage from "../Components/StatusMessage";
import settings from "../res/img/settings.svg";
import theme from "../res/img/theme.svg";

export class SelfCheck extends Component<any, any> {
  state = { range: {} };

  load = (_id: any = null, range: Object = {}) => {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    dispatch(startGetCounts([_id || id], range));
  };

  componentWillMount = (nextProps: any) => {
    this.load();
  };

  componentWillReceiveProps = (nextProps: any) => {
    const id = this.props.match.params.id;
    const newId = nextProps.match.params.id;
    if (id !== newId) this.load(newId);
  };

  dateChange = (range: Object) => {
    const { from, to } = range;
    if (from && to) this.load(null, range);
    this.setState({ range });
  };

  delete = (id: any) => () => {
    const { lang, dispatch } = this.props;
    const res = window.confirm(lang.confirmDeleteSC);
    if (res) dispatch(startDeleteSelfCheck(id));
  };

  export = () => {
    const { range } = this.state;
    const { dispatch, match } = this.props;
    const { id } = match.params;
    dispatch(startExportCounts([id], range));
  };

  logout = () => {
    const { dispatch } = this.props;
    dispatch(startLogout());
  };

  render() {
    const { libraries, counts, ui, canAdmin, lang, match } = this.props;
    const { id } = match.params;
    const selfCheck = getSelfCheck(libraries, id);
    const library =
      selfCheck && getLibrary(libraries, selfCheck.get("library_id"));

    return selfCheck ? (
      <Container>
        <Body>
          <MainSidebar
            lang={lang}
            libraries={libraries}
            id={id}
            canAdmin={canAdmin}
            logout={this.logout}
          ></MainSidebar>
          <MainArea id="main-area">
            {ui.has(DELETE_SC_PREFIX) ? (
              <StatusMessage lang={lang} object={ui.get(DELETE_SC_PREFIX)} />
            ) : null}
            <Row>
              <Col size={1} center={true}>
                {canAdmin ? (
                  <React.Fragment>
                    <Link to={"/settings/" + selfCheck.get("id")}>
                      <img alt="" src={settings} width={45} />
                    </Link>
                    <Link to={"/theme/" + selfCheck.get("id")}>
                      <img alt="" src={theme} width={45} />
                    </Link>
                  </React.Fragment>
                ) : null}
              </Col>
              <Col size={7} bold={true} center={true}>
                <Breadcrumb>
                  {selfCheck ? (
                    <React.Fragment>
                      {library.get("name")} - {selfCheck.get("name")}
                      <h4>{selfCheck.get("id")}</h4>
                      {canAdmin && selfCheck.hasIn(["metadata", "version"]) ? (
                        <h6>
                          Kiosk version{" "}
                          {selfCheck.getIn(["metadata", "version"])}
                        </h6>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </Breadcrumb>
              </Col>
              <Col size={2} center={true}>
                {canAdmin ? (
                  <Btn
                    icon={1}
                    danger={1}
                    onClick={this.delete(selfCheck.get("id"))}
                  >
                    <FA name="remove" /> {lang.delete}
                  </Btn>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col size={8}>
                <DateInput lang={lang} onChange={this.dateChange} />
              </Col>
              <Col size={2}>
                <Btn icon={1} onClick={this.export}>
                  <FA name="download" /> {lang.export}
                </Btn>
              </Col>
            </Row>
            <div style={{ padding: "20px 50px" }}>
              <CountsChart lang={lang} counts={counts.toJS()} />
            </div>
          </MainArea>
        </Body>
        <Footer />
      </Container>
    ) : null;
  }
}

export default connect((state: State) => ({
  canAdmin: getCanAdmin(state),
  ui: getUI(state),
  counts: getCounts(state),
  libraries: getLibraries(state),
  lang: getLang(state),
}))(SelfCheck);
