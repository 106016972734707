/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import keys from "lodash/keys";
import mapValues from "lodash/mapValues";
import flatten from "lodash/flatten";
import values from "lodash/values";
import keyBy from "lodash/keyBy";
import moment from "moment";
import { fromJS, Map } from "immutable";

import { SEND_PAYMENTS_PREFIX } from "../../Data/constants/actions";

import {
  startLogout,
  startExportPayments,
  startGetPayments,
  startSendPayments,
  triggerPrint,
} from "../../Data/actionCreators";
import {
  getUI,
  getCanAdmin,
  getLang,
  getLibraries,
  getPayments,
  getSelfChecks,
} from "../../Data/selectors";

import { Container, Body, Footer } from "../StyledComponents/Page";
import { RowG, ColG } from "../StyledComponents/Grid";
import { MainArea, Table } from "../StyledComponents/Main";
import DateInput from "../Components/Inputs/DateInput";
import { SettingsTitle } from "../StyledComponents/Settings";
import MainSidebar from "../Components/MainSidebar";
import { Btn } from "../StyledComponents/Forms";
import FA from "react-fontawesome";
import { SelectContainer, SelectBtn } from "../StyledComponents/Forms";
import StatusMessage from "../Components/StatusMessage";
import Help from "../Components/Help";

export class Payments extends Component<any, any> {
  state = { range: {}, reportTitle: "customTakings", disabled: false };

  componentWillMount = (nextProps: any) => {
    this.load();
    this.interval = window.setInterval(() => {
      const { range } = this.state;
      this.load(range);
    }, 60 * 1000);
  };

  componentWillUnmount = () => {
    window.clearInterval(this.interval);
    this.interval = null;
  };

  load = (range: Object = {}) => {
    const { dispatch, type } = this.props;
    dispatch(startGetPayments(type, range));
  };

  dateChange = (range: Object) => {
    const { from, to } = range;
    const { timeFrom = "", timeTo = "" } = this.state.range;
    const newRange = { from, to, timeFrom, timeTo };
    if (from && to) this.load(newRange);
    this.setState({ range: newRange });
  };

  timeChange = (attr) => (e) => {
    const { range } = this.state;
    range[attr] = e.target.value;
    if (range.timeFrom && range.timeTo) this.load(range);
    this.setState({ range });
  };

  changeReport = (e: Event) => {
    const value = e.target.value;
    const { timeFrom = "", timeTo = "" } = this.state.range;
    let range = {},
      disabled = true;
    if (value === "dayTakings") {
      range = {
        from: moment().toDate(),
        to: moment().toDate(),
        timeFrom,
        timeTo,
      };
    } else if (value === "weekTakings") {
      range = {
        from: moment().startOf("week").toDate(),
        to: moment().endOf("week").toDate(),
        timeFrom,
        timeTo,
      };
    } else if (value === "monthTakings") {
      range = {
        from: moment().startOf("month").toDate(),
        to: moment().endOf("month").toDate(),
        timeFrom,
        timeTo,
      };
    } else if (value === "last7Takings") {
      range = {
        from: moment().subtract(7, "days").toDate(),
        to: moment().toDate(),
        timeFrom,
        timeTo,
      };
    } else if (value === "last30Takings") {
      range = {
        from: moment().subtract(30, "days").toDate(),
        to: moment().toDate(),
        timeFrom,
        timeTo,
      };
    } else if (value === "customTakings") {
      range = { timeFrom, timeTo };
      disabled = false;
    }
    this.setState({ reportTitle: value, range, disabled: disabled });
    this.load(range);
  };

  print = () => {
    const { dispatch, libraries, payments, lang, type } = this.props;
    const { reportTitle, ids, range } = this.state;

    let libName = "",
      kioskId = "",
      kioskName = "";
    const _ids = keys(pickBy(ids, identity));
    if (_ids.length === 1) {
      const librariesJS = libraries.toJS();
      const libraryNames = mapValues(librariesJS, (x) => x.name);
      const libraryKiosks = keyBy(
        flatten(values(mapValues(librariesJS, (x) => x.selfChecks))),
        "id"
      );
      kioskId = _ids[0];
      const kiosk = libraryKiosks[kioskId];
      kioskName = kiosk ? kiosk.name : "";
      libName = kiosk ? libraryNames[kiosk.library_id] : "";
    }

    const combinedPayments = fromJS(
      payments.reduce(
        (acc, payment) => {
          return {
            count: acc.count + payment.get("count", 0),
            cash: acc.cash + payment.get("cash", 0),
            card: acc.card + payment.get("card", 0),
            change: acc.change + payment.get("cash", 0),
            ffloat: acc.ffloat + payment.get("ffloat", 0),
            notes: acc.notes + payment.get("notes", 0),
          };
        },
        { count: 0, cash: 0, card: 0, change: 0, ffloat: 0, notes: 0 }
      )
    );

    const data = {
      title: type === "payment" ? lang.libraryPayments : lang.councilPayments,
      libName: libName ? lang.libName + ": " + libName : "",
      kioskId: kioskId ? lang.kioskId + ": " + kioskId : "",
      kioskName: kioskName ? lang.kioskName + ": " + kioskName : "",
      intervalTitle: lang[reportTitle],
      intervalDur:
        moment(range.from).format("DD/MM/YYYY HH:mm:ss Z") +
        " To " +
        moment(range.to).format("DD/MM/YYYY HH:mm:ss Z"),
      totalTX: lang.totalTX + ": " + combinedPayments.get("count"),
      cashPaidIn:
        lang.cashPaidIn + ": " + combinedPayments.get("cash").toFixed(2),
      cardPaidIn:
        lang.cardPaidIn + ": " + combinedPayments.get("card").toFixed(2),
      float: lang.float + ": " + combinedPayments.get("ffloat").toFixed(2),
      notes: lang.notes + ": " + combinedPayments.get("notes").toFixed(2),
      cashChangeGiven:
        lang.cashChangeGiven + ": " + combinedPayments.get("change").toFixed(2),
      overallCash:
        lang.overallCash +
        ": " +
        (combinedPayments.get("cash") - combinedPayments.get("change")).toFixed(
          2
        ),
    };
    dispatch(triggerPrint(data));
  };

  export = () => {
    const { range } = this.state;
    const { dispatch, type } = this.props;
    dispatch(startExportPayments(type, range));
  };

  email = () => {
    const email = prompt("Please enter email address");
    if (email) {
      const { dispatch } = this.props;
      const paymentsTable = this.calc();
      dispatch(startSendPayments(email, paymentsTable));
    }
  };

  logout = () => {
    const { dispatch } = this.props;
    dispatch(startLogout());
  };

  calc = () => {
    const { libraries, payments } = this.props;
    const selfChecks = getSelfChecks(libraries) || new Map();
    const indexedPayments = Map(
      payments.map((v) => [v.get("selfcheck_id"), v])
    );
    return selfChecks.valueSeq().map((selfCheck) => {
      const payment = indexedPayments.get(selfCheck.get("id")) || new Map();
      return fromJS({
        id: selfCheck.get("id"),
        library: libraries.get(selfCheck.get("library_id")).get("name"),
        selfCheck: selfCheck.get("name"),
        ffloat: (payment.get("ffloat") || 0).toFixed(2),
        notes: (payment.get("notes") || 0).toFixed(2),
        cash: (payment.get("cash") || 0).toFixed(2),
        card: (payment.get("card") || 0).toFixed(2),
        change: (payment.get("change") || 0).toFixed(2),
      });
    });
  };

  render() {
    const { ui, libraries, canAdmin, lang, type } = this.props;
    const { range, reportTitle, disabled } = this.state;
    const { from, to, timeFrom = "", timeTo = "" } = range;
    const paymentsTable = this.calc();

    return (
      <Container>
        <Body>
          <MainSidebar
            lang={lang}
            libraries={libraries}
            canAdmin={canAdmin}
            logout={this.logout}
          ></MainSidebar>
          <MainArea id="main-area">
            <SettingsTitle>
              {type === "payment" ? lang.libraryPayments : lang.councilPayments}{" "}
              <Help
                attr={
                  type === "payment"
                    ? "payments.libraryPayments"
                    : "payments.councilPayments"
                }
              />
            </SettingsTitle>

            {ui.has(SEND_PAYMENTS_PREFIX) ? (
              <div style={{ marginBottom: 20 }}>
                <StatusMessage
                  lang={lang}
                  object={ui.get(SEND_PAYMENTS_PREFIX)}
                />
              </div>
            ) : null}

            <RowG style={{ height: 60 }}>
              <ColG size={4}>
                <DateInput
                  disabled={disabled}
                  lang={lang}
                  onChange={this.dateChange}
                  from={from}
                  to={to}
                />
              </ColG>
              <ColG size={1} style={{ marginRight: 5 }}>
                <input
                  type="time"
                  onChange={this.timeChange("timeFrom")}
                  value={timeFrom}
                />
              </ColG>
              <ColG size={1}>
                <input
                  type="time"
                  onChange={this.timeChange("timeTo")}
                  value={timeTo}
                />
              </ColG>
              <ColG size={3} style={{ marginLeft: 20 }}>
                <SelectContainer>
                  <select onChange={this.changeReport} value={reportTitle}>
                    {[
                      "customTakings",
                      "dayTakings",
                      "weekTakings",
                      "last7Takings",
                      "monthTakings",
                      "last30Takings",
                    ].map((e) => (
                      <option key={e} value={e}>
                        {lang[e]}
                      </option>
                    ))}
                  </select>
                  <SelectBtn />
                </SelectContainer>
              </ColG>
            </RowG>

            <RowG style={{ marginTop: 20 }}>
              <Table>
                <thead>
                  <tr>
                    <th>Location</th>
                    <th>Kiosk</th>
                    <th>Coin Float</th>
                    <th>Notes</th>
                    <th>Cash</th>
                    <th>Card</th>
                    <th>Change given</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentsTable.valueSeq().map((row) => {
                    return (
                      <tr key={row.get("id")}>
                        <td>{row.get("library")}</td>
                        <td>{row.get("selfCheck")}</td>
                        <td>{row.get("ffloat")}</td>
                        <td>{row.get("notes")}</td>
                        <td>{row.get("cash")}</td>
                        <td>{row.get("card")}</td>
                        <td>{row.get("change")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </RowG>
            <RowG style={{ marginTop: 40 }}>
              <Btn
                primary={true}
                style={{ marginRight: 10 }}
                onClick={this.export}
              >
                <FA name="save" /> Export CSV
              </Btn>
              <Btn onClick={this.print} style={{ marginRight: 10 }}>
                <FA name="print" /> Print
              </Btn>
              <Btn onClick={this.email}>
                <FA name="envelope" /> Email
              </Btn>
            </RowG>
          </MainArea>
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  ui: getUI(state),
  canAdmin: getCanAdmin(state),
  libraries: getLibraries(state),
  lang: getLang(state),
  payments: getPayments(state),
}))(Payments);
