/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getLang,
  getCanAdmin,
  getLibraries,
  getMisc,
  getUI,
} from "../../Data/selectors";
import {
  startGetHelpMessages,
  startLogout,
  startSaveHelp,
} from "../../Data/actionCreators";

import { Container, Body, Footer } from "../StyledComponents/Page";
import { Row, Col, ColL } from "../StyledComponents/Grid";
import { MainArea } from "../StyledComponents/Main";
import MainSidebar from "../Components/MainSidebar";
import ImgInput from "../Components/Inputs/ImgInput";
import TextAreaInput from "../Components/Inputs/TextAreaInput";
import { Btn } from "../StyledComponents/Forms";
import FA from "react-fontawesome";
import { Map } from "immutable";
import { SAVE_HELP_PREFIX } from "../../Data/constants/actions";
import StatusMessage from "../Components/StatusMessage";

const defaultHelp = [
  "menuScreen",
  "loginScreen",
  "pinScreen",
  "borrowScreen",
  "returnScreen",
  "reservationScreen",
  "payCashScreen",
  "payCardScreen",
  "renewalScreen",
];

export class HelpMessages extends Component<any, any> {
  state = { helpMessages: new Map() };

  logout = () => {
    const { dispatch } = this.props;
    dispatch(startLogout());
  };

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(startGetHelpMessages());
  }

  componentDidUpdate(prevProps) {
    if (this.props.helpMessages !== prevProps.helpMessages) {
      this.setState({ helpMessages: this.props.helpMessages });
    }
  }

  onChange = (path) => (value) => {
    const { helpMessages } = this.state;
    const newHelpMessages = helpMessages.setIn(path, value);
    console.log(value, helpMessages, newHelpMessages);
    this.setState({ helpMessages: newHelpMessages });
  };

  saveHelp = () => {
    const { dispatch } = this.props;
    const { helpMessages } = this.state;
    dispatch(startSaveHelp(helpMessages));
  };

  render() {
    const { ui, libraries, canAdmin, lang } = this.props;
    const { helpMessages } = this.state;

    return (
      <Container>
        <Body>
          <MainSidebar
            lang={lang}
            libraries={libraries}
            id={null}
            canAdmin={canAdmin}
            logout={this.logout}
          ></MainSidebar>
          <MainArea id="main-area">
            {ui.has(SAVE_HELP_PREFIX) ? (
              <StatusMessage lang={lang} object={ui.get(SAVE_HELP_PREFIX)} />
            ) : null}

            <Row>
              <Col size={8} bold={true} center={true}>
                <h1 style={{ textAlign: "center" }}>{lang.helpMessages}</h1>
              </Col>
              <Col size={2}>
                <Btn onClick={this.saveHelp}>
                  <FA name="save" /> {lang.save}
                </Btn>
              </Col>
            </Row>
            <div>
              {defaultHelp.map((key, i) => (
                <Row key={i} tiny={1}>
                  <ColL size={1}>{lang[key]}</ColL>
                  <ColL size={2}>
                    <TextAreaInput
                      placeholder={lang.helpMessageValue}
                      obj={helpMessages}
                      path={[key, "text"]}
                      onChange={this.onChange}
                    />
                  </ColL>
                  <Col size={2}>
                    <ImgInput
                      obj={helpMessages}
                      path={[key, "img"]}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ))}
            </div>
          </MainArea>
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  canAdmin: getCanAdmin(state),
  ui: getUI(state),
  libraries: getLibraries(state),
  helpMessages: getMisc(state, "help")
    ? getMisc(state, "help").get("payload")
    : new Map(),
  lang: getLang(state),
}))(HelpMessages);
