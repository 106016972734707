/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FA from "react-fontawesome";

import {
  getLang,
  getCanAdmin,
  getUI,
  getLibraries,
  getLibrary,
} from "../../Data/selectors";
import { DELETE_SC_PREFIX } from "../../Data/constants/actions";
import {
  startDeleteLibrary,
  startDeleteSelfCheck,
  startLogout,
} from "../../Data/actionCreators";

import { Container, Body, Footer } from "../StyledComponents/Page";
import { Btn } from "../StyledComponents/Forms";
import { Row, Col } from "../StyledComponents/Grid";
import { MainArea, StatusTable } from "../StyledComponents/Main";
import MainSidebar from "../Components/MainSidebar";
import StatusMessage from "../Components/StatusMessage";
import green_tick from "../res/img/green_tick.svg";
import red_cross from "../res/img/red_cross.svg";
import settings from "../res/img/settings.svg";
import theme from "../res/img/theme.svg";

export class Library extends Component<any, any> {
  delete = (id: any) => () => {
    const { lang, dispatch } = this.props;
    const res = window.confirm(lang.confirmDeleteLib);
    if (res) dispatch(startDeleteLibrary(id));
  };

  deleteSelfCheck = (id: any) => () => {
    const { lang, dispatch } = this.props;
    const res = window.confirm(lang.confirmDeleteSC);
    if (res) dispatch(startDeleteSelfCheck(id));
  };

  logout = () => {
    const { dispatch } = this.props;
    dispatch(startLogout());
  };

  render() {
    const { libraries, ui, canAdmin, lang, match } = this.props;
    const { id } = match.params;
    const library = getLibrary(libraries, id);

    return library ? (
      <Container>
        <Body>
          <MainSidebar
            lang={lang}
            libraries={libraries}
            id={id}
            canAdmin={canAdmin}
            logout={this.logout}
          ></MainSidebar>
          <MainArea id="main-area">
            {ui.has(DELETE_SC_PREFIX) ? (
              <StatusMessage lang={lang} object={ui.get(DELETE_SC_PREFIX)} />
            ) : null}
            <Row>
              <Col size={6} bold={true} center={true}>
                <h1 style={{ textAlign: "center" }}>{library.get("name")}</h1>
              </Col>
              <Col size={4} center={true}>
                {canAdmin ? (
                  <React.Fragment>
                    <Link
                      to={"/libraries/" + id + "/create"}
                      style={{ marginRight: 10 }}
                    >
                      <Btn>
                        <FA name="plus" />
                        {lang.create}
                      </Btn>
                    </Link>
                    <Link
                      to={"/libraries/" + id + "/edit"}
                      style={{ marginRight: 10 }}
                    >
                      <Btn>
                        <FA name="pencil" />
                        {lang.edit}
                      </Btn>
                    </Link>
                    <Btn
                      icon={1}
                      danger={1}
                      onClick={this.delete(library.get("id"))}
                    >
                      <FA name="remove" /> {lang.delete}
                    </Btn>
                  </React.Fragment>
                ) : null}
              </Col>
            </Row>
            <Row bb={true}>
              {canAdmin ? <Col size={1}></Col> : null}
              <Col size={2} bold={true} center={true}>
                {lang.device}
              </Col>
              <Col size={2} bold={true} center={true}>
                {lang.status}
              </Col>
              <Col size={4} bold={true} center={true}>
                {lang.statusMsg}
              </Col>
              {canAdmin ? (
                <Col size={2} bold={true} center={true}>
                  {lang.configuration}
                </Col>
              ) : null}
            </Row>
            <StatusTable>
              {library.get("selfChecks").map((selfCheck) => {
                const status = selfCheck.get("status");

                return (
                  <Row bb={true} key={selfCheck.get("id")}>
                    {canAdmin ? (
                      <Col size={1}>
                        <Btn
                          icon={1}
                          danger={1}
                          tiny={1}
                          onClick={this.deleteSelfCheck(selfCheck.get("id"))}
                        >
                          <FA name="remove" />
                        </Btn>
                        <Link
                          to={"/selfchecks/" + selfCheck.get("id") + "/edit"}
                        >
                          <Btn icon={1} tiny={1} style={{ marginLeft: 5 }}>
                            <FA name="edit" />
                          </Btn>
                        </Link>
                      </Col>
                    ) : null}

                    <Col size={2} center={true}>
                      {selfCheck.get("name")}
                    </Col>
                    <Col size={2} center={true}>
                      <img
                        alt=""
                        src={status === 1 ? green_tick : red_cross}
                        width={45}
                      />
                    </Col>
                    <Col size={4} center={true}>
                      {selfCheck.get("message")}
                    </Col>
                    {canAdmin
                      ? [
                          <Col size={1} center={true} key="1">
                            <Link to={"/settings/" + selfCheck.get("id")}>
                              <img alt="" src={settings} width={45} />
                            </Link>
                          </Col>,
                          <Col size={1} center={true} key="2">
                            <Link to={"/theme/" + selfCheck.get("id")}>
                              <img alt="" src={theme} width={45} />
                            </Link>
                          </Col>,
                        ]
                      : null}
                  </Row>
                );
              })}
            </StatusTable>
          </MainArea>
        </Body>
        <Footer />
      </Container>
    ) : null;
  }
}

export default connect((state: State) => ({
  canAdmin: getCanAdmin(state),
  ui: getUI(state),
  libraries: getLibraries(state),
  lang: getLang(state),
}))(Library);
