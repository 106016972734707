/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import { CREATE_LIB_PREFIX } from "../../Data/constants/actions";
import {
  getLang,
  getUI,
  getCanAdmin,
  getLibraries,
} from "../../Data/selectors";

import { Container, Body, Footer } from "../StyledComponents/Page";
import { MainArea } from "../StyledComponents/Main";
import MainSidebar from "../Components/MainSidebar";
import StatusMessage from "../Components/StatusMessage";
import { FormGroup, LoginForm, Btn } from "../StyledComponents/Forms";
import { createLibrary } from "../../Data/actionCreators";

export class LibraryCreate extends Component<any, any> {
  state = { name: "" };

  change = (key: string) => (e: Event) => {
    this.setState({ [key]: e.target.value });
  };

  submit = (e: Event) => {
    const { dispatch } = this.props;
    const { name } = this.state;
    dispatch(createLibrary(name));
  };

  render() {
    const { libraries, ui, lang, canAdmin } = this.props;
    const { name } = this.state;

    return (
      <Container>
        <Body>
          <MainSidebar
            lang={lang}
            libraries={libraries}
            canAdmin={canAdmin}
          ></MainSidebar>
          <MainArea id="main-area">
            {ui.has(CREATE_LIB_PREFIX) ? (
              <StatusMessage lang={lang} object={ui.get(CREATE_LIB_PREFIX)} />
            ) : null}
            <LoginForm>
              <h1>{lang.createLibTitle}</h1>
              <FormGroup>
                <label htmlFor="name">{lang.libName}</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={this.change("name")}
                />
              </FormGroup>
              <Btn onClick={this.submit}>{lang.create}</Btn>
            </LoginForm>
          </MainArea>
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  canAdmin: getCanAdmin(state),
  ui: getUI(state),
  libraries: getLibraries(state),
  lang: getLang(state),
}))(LibraryCreate);
