/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import { UPDATE_USERS_PREFIX } from "../../Data/constants/actions";
import {
  getLang,
  getUI,
  getUsers,
  getCanAdmin,
  getLibraries,
  getUser,
} from "../../Data/selectors";
import { startUpdateUser } from "../../Data/actionCreators";

import { Container, Body, Footer } from "../StyledComponents/Page";
import { MainArea } from "../StyledComponents/Main";
import MainSidebar from "../Components/MainSidebar";
import StatusMessage from "../Components/StatusMessage";
import { FormGroup, LoginForm, Btn } from "../StyledComponents/Forms";
import { uiSet } from "../../Data/actionCreators";
import SelectInput from "../Components/Inputs/SelectInput";
import TextInput from "../Components/Inputs/TextInput";
import PasswordInput from "../Components/Inputs/PasswordInput";

export class UserEdit extends Component<any, any> {
  state = { obj: fromJS({ username: "", password: "", userType: 10 }) };

  componentWillMount() {
    const { match, users } = this.props;
    const { id } = match.params;
    const user = getUser(users, id);
    const newObj = fromJS({
      id: user.get("id"),
      username: user.get("username"),
      password: "",
      userType: user.get("user_type"),
    });
    this.setState({ obj: newObj });
  }

  onChange = (path: Array<string>) => (value: any) => {
    const { obj } = this.state;
    this.setState({ obj: obj.setIn(path, value) });
  };

  submit = (e: Event) => {
    const { dispatch } = this.props;
    const { obj } = this.state;
    const id = obj.get("id");
    const username = obj.get("username");
    const password = obj.get("password");
    const userType = obj.get("userType");
    if (!!(username && userType)) {
      dispatch(startUpdateUser(id, username, password, userType));
    } else {
      dispatch(
        uiSet({
          path: [UPDATE_USERS_PREFIX],
          value: { type: "error", message: "error_invalid_entries" },
        })
      );
    }
  };

  render() {
    const { libraries, canAdmin, ui, lang } = this.props;
    const { obj } = this.state;

    return (
      <Container>
        <Body>
          <MainSidebar
            lang={lang}
            libraries={libraries}
            canAdmin={canAdmin}
          ></MainSidebar>
          <MainArea id="main-area">
            {ui.has(UPDATE_USERS_PREFIX) ? (
              <StatusMessage lang={lang} object={ui.get(UPDATE_USERS_PREFIX)} />
            ) : null}
            <LoginForm>
              <h1>{lang.editUserTitle}</h1>
              <FormGroup>
                <label htmlFor="name">{lang.username}</label>
                <TextInput
                  type="text"
                  id="name"
                  path={["username"]}
                  obj={obj}
                  onChange={this.onChange}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="password">
                  {lang.password} {lang.passwordNotice}
                </label>
                <PasswordInput
                  id="password"
                  path={["password"]}
                  obj={obj}
                  onChange={this.onChange}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="userType">{lang.userType}</label>
                <SelectInput
                  id="userType"
                  optionCollection={true}
                  options={lang.userTypesArr}
                  path={["userType"]}
                  obj={obj}
                  onChange={this.onChange}
                />
              </FormGroup>
              <Btn onClick={this.submit}>{lang.save}</Btn>
            </LoginForm>
          </MainArea>
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  canAdmin: getCanAdmin(state),
  ui: getUI(state),
  users: getUsers(state),
  libraries: getLibraries(state),
  lang: getLang(state),
}))(UserEdit);
