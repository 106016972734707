/* @flow */

import {
  INIT,
  UI_SET,
  UI_UNSET,
  FATAL_ERROR,
  REQUEST,
  DO_LOGOUT,
  CLEAR_ACCOUNT,
  LOGIN_START,
  GET_LIBS_START,
  CREATE_LIB_START,
  EDIT_LIB_START,
  CREATE_SC_START,
  EDIT_SC_START,
  GET_SETTINGS_START,
  SAVE_SETTINGS_START,
  SET_SETTINGS,
  GET_LANGUAGES_START,
  SAVE_LANGUAGES_START,
  SET_LANGAUGES,
  DELETE_LIB_START,
  DELETE_SC_START,
  GET_THEMES_START,
  SAVE_THEMES_START,
  SET_THEME,
  GET_INFO_START,
  DO_UPDATE_START,
  GET_USERS_START,
  CREATE_USERS_START,
  UPDATE_USERS_START,
  DELETE_USERS_START,
  GET_COUNTS_START,
  GET_MAIN_STATS_START,
  EXPORT_COUNTS_START,
  GET_PAYMENTS_START,
  EXPORT_PAYMENTS_START,
  SEND_PAYMENTS_START,
  GET_REPORT_START,
  SAVE_DATES_START,
  GET_GEN_SETTINGS_START,
  SAVE_GEN_SETTINGS_START,
  TRIGGER_PRINT,
  TRIGGER_ZPRINT,
  GET_LIVE_UPDATES_START,
  GET_HELP_START,
  SAVE_HELP_START,
} from "./constants/actions";

export const init = () => ({ type: INIT, payload: {} });

export const uiSet = (payload: Object) => ({ type: UI_SET, payload });

export const uiUnset = (payload: Object) => ({ type: UI_UNSET, payload });

export const fatalError = (payload: Object) => ({ type: FATAL_ERROR, payload });

export const doRequest = (payload: Object) => ({ type: REQUEST, payload });

export const startGetLibraries = () => ({ type: GET_LIBS_START, payload: {} });

export const startLogin = (username: string, password: string) => ({
  type: LOGIN_START,
  payload: { username, password },
});

export const createLibrary = (name: string) => ({
  type: CREATE_LIB_START,
  payload: { name },
});

export const editLibrary = (id: string, name: string) => ({
  type: EDIT_LIB_START,
  payload: { id, name },
});

export const createSelfCheck = (id: string, name: string) => ({
  type: CREATE_SC_START,
  payload: { id, name },
});

export const editSelfCheck = (id: string, name: string) => ({
  type: EDIT_SC_START,
  payload: { id, name },
});

export const startGetSettings = (id: any) => ({
  type: GET_SETTINGS_START,
  payload: { id },
});

export const startSaveSettings = (
  id: any,
  settings: any,
  saveChoices: object
) => ({ type: SAVE_SETTINGS_START, payload: { id, settings, saveChoices } });

export const startGetGenSettings = () => ({
  type: GET_GEN_SETTINGS_START,
});

export const startSaveGenSettings = (genSettings: object) => ({
  type: SAVE_GEN_SETTINGS_START,
  payload: { genSettings },
});

export const setSetting = (path: Array<string>, value: any) => ({
  type: SET_SETTINGS,
  payload: { path, value },
});

export const startGetLanguages = () => ({
  type: GET_LANGUAGES_START,
  payload: {},
});

export const startSaveLanguages = (languages: any) => ({
  type: SAVE_LANGUAGES_START,
  payload: { languages },
});

export const setLanguages = (languages: IList) => ({
  type: SET_LANGAUGES,
  payload: { languages },
});

export const startDeleteLibrary = (id: string) => ({
  type: DELETE_LIB_START,
  payload: { id },
});

export const startDeleteSelfCheck = (id: string) => ({
  type: DELETE_SC_START,
  payload: { id },
});

export const startGetThemes = (id: any) => ({
  type: GET_THEMES_START,
  payload: { id },
});

export const startSaveThemes = (id: any, theme: any, saveChoices: object) => ({
  type: SAVE_THEMES_START,
  payload: { id, theme, saveChoices },
});

export const setTheme = (path: Array<string>, value: any) => ({
  type: SET_THEME,
  payload: { path, value },
});

export const startGetMainStats = () => ({
  type: GET_MAIN_STATS_START,
  payload: {},
});
export const startGetInfo = () => ({ type: GET_INFO_START, payload: {} });

export const startUpdate = () => ({ type: DO_UPDATE_START, payload: {} });

export const startLogout = (ask = true) => ({
  type: DO_LOGOUT,
  payload: { ask },
});

export const clearAccount = () => ({ type: CLEAR_ACCOUNT, payload: {} });

export const startGetUsers = () => ({ type: GET_USERS_START, payload: {} });

export const startDeleteUser = (id: any) => ({
  type: DELETE_USERS_START,
  payload: { id },
});

export const startCreateUser = (
  username: string,
  password: string,
  userType: number
) => ({ type: CREATE_USERS_START, payload: { username, password, userType } });

export const startUpdateUser = (
  id: any,
  username: string,
  password: string,
  userType: number
) => ({
  type: UPDATE_USERS_START,
  payload: { id, username, password, userType },
});

export const startGetCounts = (ids: any, range: Object) => ({
  type: GET_COUNTS_START,
  payload: { ids, range },
});

export const startExportCounts = (ids: any, range: Object) => ({
  type: EXPORT_COUNTS_START,
  payload: { ids, range },
});

export const startGetPayments = (type: string, range: Object) => ({
  type: GET_PAYMENTS_START,
  payload: { type, range },
});

export const startExportPayments = (type: string, range: Object) => ({
  type: EXPORT_PAYMENTS_START,
  payload: { type, range },
});

export const startSendPayments = (email, paymentsTable) => ({
  type: SEND_PAYMENTS_START,
  payload: { email, paymentsTable },
});

export const startGetReport = (ids: any) => ({
  type: GET_REPORT_START,
  payload: { ids },
});

export const startSaveDates = (ids: any) => ({
  type: SAVE_DATES_START,
  payload: { ids },
});

export const triggerPrint = (data: Object) => ({
  type: TRIGGER_PRINT,
  payload: { data },
});

export const triggerZPrint = (data: Object) => ({
  type: TRIGGER_ZPRINT,
  payload: { data },
});

export const startGetLiveUpdates = (data: Object) => ({
  type: GET_LIVE_UPDATES_START,
});

export const startGetHelpMessages = () => ({
  type: GET_HELP_START,
});

export const startSaveHelp = (data: Object) => ({
  type: SAVE_HELP_START,
  payload: { data },
});
