/* @flow */

import React, { Component } from "react";

import { SettingsBox, SettingsTitle } from "../../StyledComponents/Settings";
import { Row, Col, ColL } from "../../StyledComponents/Grid";
import ColorInput from "../Inputs/ColorInput";
// import ImgInput from "../Inputs/ImgInput";
// import TextAreaInput from "../Inputs/TextAreaInput";
// import SelectInput from "../Inputs/SelectInput";
import CheckBox from "../Inputs/CheckBox";
import Help from "../Help";

export default class Design extends Component<any, any> {
  onChange = (path: Array<string>) => (e: Event) => {
    const { onChange } = this.props;
    onChange(path)(e.target.value);
  };

  render() {
    const { settings, lang, onChange } = this.props;

    return (
      <div>
        <SettingsTitle>{lang.settings.design}</SettingsTitle>
        <SettingsBox>
          <Row>
            <ColL size={2}>
              {lang.settings.bgColor}
              <Help attr="design.bgColor" />
            </ColL>
            <Col size={2}>
              <ColorInput
                obj={settings}
                path={["design", "bgColor"]}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "bgColor"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.headerColor}
              <Help attr="design.headerColor" />
            </ColL>
            <Col size={2}>
              <ColorInput
                obj={settings}
                path={["design", "headerColor"]}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "headerColor"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.footerColor}
              <Help attr="design.footerColor" />
            </ColL>
            <Col size={2}>
              <ColorInput
                obj={settings}
                path={["design", "footerColor"]}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "footerColor"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          {/* <Row>
            <ColL size={2}>
              {lang.settings.bgImg}
              <Help attr="design.bgImg" />
            </ColL>
            <Col size={2}>
              <ImgInput
                obj={settings}
                path={["design", "bgImg"]}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "bgImg"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row> */}
          {/* <Row>
            <ColL size={2}>
              {lang.settings.fontFamily}
              <Help attr="design.fontFamily" />
            </ColL>
            <Col size={2}>
              <SelectInput
                options={[
                  "",
                  "Arial",
                  "Comic Sans MS",
                  "Courier New",
                  "Georgia",
                  "Helvetica",
                  "Impact",
                  "Lucida Console",
                  "Palatino",
                  "Tahoma",
                  "Times New Roman",
                  "Verdana",
                ]}
                path={["design", "fontFamily"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "fontFamily"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row> */}
          {/* <Row>
            <ColL size={2}>
              {lang.settings.warningPopUp}
              <Help attr="design.warningPopUp" />
            </ColL>
            <Col size={2}>
              <TextAreaInput
                obj={settings}
                path={["design", "warningPopUp"]}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "warningPopUp"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row> */}
          {/* <Row>
            <ColL size={2}>
              {lang.settings.failurePopUp}
              <Help attr="design.failurePopUp" />
            </ColL>
            <Col size={2}>
              <TextAreaInput
                obj={settings}
                path={["design", "failurePopUp"]}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "failurePopUp"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row> */}
        </SettingsBox>
      </div>
    );
  }
}
