/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import FA from "react-fontawesome";
import { fromJS, Map } from "immutable";
import { Prompt } from "react-router-dom";

import {
  getUI,
  getLibraries,
  getTheme,
  getLang,
  getSelfCheck,
  getLibrary,
  getCanAdmin,
} from "../../Data/selectors";
import { SAVE_THEMES_PREFIX } from "../../Data/constants/actions";
import {
  startGetThemes,
  startSaveThemes,
  setTheme,
} from "../../Data/actionCreators";

import StatusMessage from "../Components/StatusMessage";
import { Container, Body, Footer } from "../StyledComponents/Page";
import { Row, Col, ColL } from "../StyledComponents/Grid";
import { Btn } from "../StyledComponents/Forms";
import {
  MainArea,
  PopUp,
  PopUpBody,
  CenterPage,
  ErrorTxt,
  StatusTxt,
  Breadcrumb,
} from "../StyledComponents/Main";
import ThemeEditor from "../Components/ThemeEditor";
import ThemeSidebar from "../Components/ThemeSidebar";
import ComponentSidebar from "../Components/ComponentSidebar";
import Radio from "../Components/Inputs/Radio";
import { Tree } from "antd";

const { TreeNode } = Tree;

export class Theme extends Component<any, any> {
  state = {
    saveChoices: fromJS({ saveOption: "ws" }),
    savePopupOpen: false,
    importPopupOpen: false,
    dirty: false,
  };

  componentWillMount() {
    const { match, dispatch } = this.props;
    const id = match.params.id;
    this.setState({ dirty: false });
    dispatch(startGetThemes(id));
  }

  componentWillReceiveProps(nextProps) {
    try {
      const { dispatch } = this.props;
      const oldTab = this.props.match.params.component;
      const tab = nextProps.match.params.component;
      if (oldTab !== tab) {
        this.setState({ dirty: false });
        dispatch(setTheme(["__updates"], new Map()));
      }
    } catch (e) {}
  }

  reload = () => window.location.reload();

  openSavePopup = () => this.setState({ savePopupOpen: true });
  closeSavePopup = () => this.setState({ savePopupOpen: false });
  openImportPopup = () => this.setState({ importPopupOpen: true });
  closeImportPopup = () => this.setState({ importPopupOpen: false });

  onSaveChange = (path: Array<string>) => (value: any) => {
    const { saveChoices } = this.state;
    const newSaveChoices = saveChoices.setIn(path, value);
    this.setState({ saveChoices: newSaveChoices });
  };

  saveTheme = () => {
    const { match, theme, dispatch } = this.props;
    const id = match.params.id;
    const screen = match.params.screen;
    const component = match.params.component;
    const saveChoices = this.state.saveChoices.toJS();
    const { selfChecks = [] } = saveChoices;
    const newSelfChecks = selfChecks.filter((e) => !~e.indexOf("ignore-"));
    const newSaveChoices = { ...saveChoices, selfChecks: newSelfChecks };
    const subTheme = theme.getIn(["theme", screen, component], new Map());
    const __updates = theme.getIn(["__updates", screen, component], new Map());
    let themesToSave = new Map();
    const dfs = (path, e) => {
      if (!!e.toJS) {
        e.keySeq().forEach((k) => dfs([...path, k], e.get(k)));
      } else if (e) {
        themesToSave = themesToSave.setIn(path, subTheme.getIn(path));
      }
    };
    dfs([], __updates);
    const payloadTheme = new Map().setIn(
      ["theme", screen, component],
      themesToSave
    );
    dispatch(startSaveThemes(id, payloadTheme, newSaveChoices));
    dispatch(setTheme(["__updates"], new Map()));
    this.setState({ dirty: false });
    this.closeSavePopup();
  };

  onSelectTree = (checkedKeys) => {
    const { saveChoices } = this.state;
    const newSaveChoices = saveChoices.set("selfChecks", checkedKeys);
    this.setState({ saveChoices: newSaveChoices });
  };

  exportJson = () => {
    const { theme } = this.props;
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(theme.toJS()));
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "theme.json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  saveImportedSettings = () => {
    const { match, dispatch } = this.props;
    const id = match.params.id;
    if (this.importInput) {
      let file = this.importInput.files[0];
      let fr = new FileReader();
      fr.onload = (e) => {
        const txt = e.target.result;
        try {
          const json = JSON.parse(txt);
          if (!json.theme) throw Error();
          dispatch(
            startSaveThemes(id, json, {
              saveOption: "ws",
              selfChecks: [],
            })
          );
          this.closeImportPopup();
        } catch (e) {
          window.alert("Invalid file type");
        }
      };
      fr.readAsText(file);
    }
  };

  onChange = (path: Array<string>) => (value: any) => {
    const { dispatch } = this.props;
    this.setState({ dirty: true });
    dispatch(setTheme(path, value));
  };

  render() {
    const { ui, canAdmin, libraries, theme, lang, match } = this.props;
    const { dirty } = this.state;
    const id = match.params.id;
    const screen = match.params.screen || "welcome";
    const component = match.params.component;
    const selfCheck = getSelfCheck(libraries, id);
    const library =
      selfCheck && getLibrary(libraries, selfCheck.get("library_id"));
    const expanded = libraries
      .valueSeq()
      .map((e, i) =>
        e.get("id") === selfCheck.get("library_id") ? `ignore-${i}` : null
      )
      .filter((e) => e)
      .toJS();

    return ui.get("connectionError") ? (
      <Container>
        <CenterPage>
          <ErrorTxt>
            <h1>{lang.connectionError}</h1>
          </ErrorTxt>
          <Btn onClick={this.reload}>
            <FA name="refresh" /> {lang.retry}
          </Btn>
        </CenterPage>
      </Container>
    ) : ui.get("loadingTheme") ? (
      <Container>
        <CenterPage>
          <StatusTxt>
            <h1>Loading theme...</h1>
          </StatusTxt>
        </CenterPage>
      </Container>
    ) : (
      <Container>
        <Prompt
          when={dirty}
          message={() =>
            `You have unsaved changes, are you sure you want to discard them?`
          }
        />
        <Body>
          <ThemeSidebar lang={lang} screen={screen} id={id} />
          <ComponentSidebar
            lang={lang}
            screen={screen}
            component={component}
            id={id}
          />
          <MainArea id="main-area">
            {ui.has(SAVE_THEMES_PREFIX) ? (
              <StatusMessage lang={lang} object={ui.get(SAVE_THEMES_PREFIX)} />
            ) : null}
            <Row>
              <Col size={4}>
                {canAdmin && (
                  <Btn
                    onClick={this.openImportPopup}
                    style={{ marginRight: 10, marginLeft: "auto" }}
                  >
                    <FA name="upload" /> {lang.import}
                  </Btn>
                )}
                {canAdmin && (
                  <Btn onClick={this.exportJson} style={{ marginRight: 10 }}>
                    <FA name="download" /> {lang.export}
                  </Btn>
                )}
                <Btn onClick={this.openSavePopup}>
                  <FA name="save" /> {lang.save}
                </Btn>
              </Col>
            </Row>
            <Row>
              <Col size={2} bold={true} center={true}>
                <Breadcrumb>
                  {selfCheck ? (
                    <React.Fragment>
                      {library.get("name")} - {selfCheck.get("name")}
                      <h4>{selfCheck.get("id")}</h4>
                    </React.Fragment>
                  ) : null}
                </Breadcrumb>
              </Col>
            </Row>
            {component ? (
              <ThemeEditor
                lang={lang}
                theme={theme}
                screen={screen}
                component={component}
                onChange={this.onChange}
              />
            ) : null}
          </MainArea>
          {this.state.savePopupOpen ? (
            <PopUp>
              <PopUpBody>
                <Row tiny={true}>
                  <ColL size={1}>
                    <h2 style={{ margin: 0 }}>{lang.confirmSave}</h2>
                  </ColL>
                </Row>
                <Row tiny={true}>
                  <ColL size={0}>
                    <Radio
                      style={{ margin: "auto" }}
                      name="saveOption"
                      id="saveGlobal"
                      value="global"
                      path={["saveOption"]}
                      obj={this.state.saveChoices}
                      onChange={this.onSaveChange}
                    />
                  </ColL>
                  <ColL size={1}>
                    <label htmlFor="saveGlobal">{lang.saveGlobal}</label>
                  </ColL>
                </Row>
                <Row tiny={true}>
                  <ColL size={0}>
                    <Radio
                      style={{ margin: "auto" }}
                      name="saveOption"
                      id="saveLibrary"
                      value="library"
                      path={["saveOption"]}
                      obj={this.state.saveChoices}
                      onChange={this.onSaveChange}
                    />
                  </ColL>
                  <ColL size={1}>
                    <label htmlFor="saveLibrary">{lang.saveLibrary}</label>
                  </ColL>
                </Row>
                <Row tiny={true}>
                  <ColL size={0}>
                    <Radio
                      style={{ margin: "auto" }}
                      name="saveOption"
                      id="savePick"
                      value="pws"
                      path={["saveOption"]}
                      obj={this.state.saveChoices}
                      onChange={this.onSaveChange}
                    />
                  </ColL>
                  <ColL size={1}>
                    <label htmlFor="savePick">{lang.savePick}</label>
                  </ColL>
                </Row>
                {this.state.saveChoices.get("saveOption") === "pws" && (
                  <Row tiny={true}>
                    <Tree
                      checkable
                      defaultCheckedKeys={[id]}
                      defaultExpandedKeys={expanded}
                      onCheck={this.onSelectTree}
                    >
                      {libraries.valueSeq().map((library, i) => (
                        <TreeNode
                          title={library.get("name")}
                          key={`ignore-${i}`}
                        >
                          {library.get("selfChecks").map((selfCheck) => (
                            <TreeNode
                              title={selfCheck.get("name")}
                              key={selfCheck.get("id")}
                              disabled={selfCheck.get("id") === id}
                            />
                          ))}
                        </TreeNode>
                      ))}
                    </Tree>
                  </Row>
                )}
                <Row tiny={true}>
                  <ColL size={0}>
                    <Radio
                      name="saveOption"
                      id="saveWS"
                      value="ws"
                      path={["saveOption"]}
                      obj={this.state.saveChoices}
                      onChange={this.onSaveChange}
                    />
                  </ColL>
                  <ColL size={1}>
                    <label htmlFor="saveWS">{lang.saveWS}</label>
                  </ColL>
                </Row>
                <Row tiny={true}>
                  <ColL size={2}>
                    <Btn
                      onClick={this.closeSavePopup}
                      style={{ marginRight: 5 }}
                    >
                      <FA name="times" /> {lang.cancel}
                    </Btn>
                    <Btn onClick={this.saveTheme}>
                      <FA name="save" /> {lang.save}
                    </Btn>
                  </ColL>
                  <Col size={2}></Col>
                </Row>
              </PopUpBody>
            </PopUp>
          ) : null}
          {this.state.importPopupOpen ? (
            <PopUp>
              <PopUpBody>
                <Row tiny={true}>
                  <ColL size={1}>
                    <h2 style={{ margin: 0 }}>{lang.importTheme}</h2>
                  </ColL>
                </Row>
                <Row tiny={true}>
                  <ColL size={8}>
                    <h4>{lang.warningImport}</h4>
                    <input type="file" ref={(el) => (this.importInput = el)} />
                  </ColL>
                </Row>
                <Row tiny={true}>
                  <ColL size={2}>
                    <Btn
                      onClick={this.closeImportPopup}
                      style={{ marginRight: 5 }}
                    >
                      <FA name="times" /> {lang.cancel}
                    </Btn>
                    <Btn onClick={this.saveImportedSettings}>
                      <FA name="save" /> {lang.save}
                    </Btn>
                  </ColL>
                  <Col size={2} />
                </Row>
              </PopUpBody>
            </PopUp>
          ) : null}
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  ui: getUI(state),
  canAdmin: getCanAdmin(state),
  libraries: getLibraries(state),
  theme: getTheme(state),
  lang: getLang(state),
}))(Theme);
